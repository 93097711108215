import { Navigate, useRoutes } from "react-router-dom";
import DefaultLayout from "../layouts/default";
import NotFoundPage from "../pages/404-page";
import DefaultPage from "../pages/default-page";
import routes from "./routes";
import ExpertisePage from "../pages/expertise-page";
import ProjectsPage from "../pages/projects-page";
import ExperiencePage from "../pages/experience-page";
import ContactPage from "../pages/contact-page";
import ProjectPage from "../pages/project-page";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DefaultLayout />,
      children: [
        { path: "/", element: <DefaultPage /> },
        { path: routes.EXPERTISE, element: <ExpertisePage /> },
        { path: routes.PROJECTS, element: <ProjectsPage /> },
        { path: routes.EXPERIENCE, element: <ExperiencePage /> },
        { path: routes.CONTACT, element: <ContactPage /> },
        { path: routes.NOT_FOUND, element: <NotFoundPage /> },
        { path: `${routes.PROJECTS}/:project_name`, element: <ProjectPage /> },
        { path: "*", element: <Navigate to={routes.NOT_FOUND} /> },
      ],
    },
  ]);
}
