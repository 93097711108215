import { Box, Skeleton } from "@mui/material";
import { useState } from "react";

const Image = ({ ...image }) => {
  const [loading, setLoading] = useState(true);

  return (
    <Box
      sx={{ transition: "opacity .3s linear" }}
      position="relative"
      width="100%"
      height={image.height}
    >
      <img
        onLoad={() => setLoading(false)}
        alt={image.alt}
        src={image.src}
        height={image.height}
        width="100%"
        style={{
          objectFit: "cover",
          opacity: loading ? 0 : 1,
          transition: "inherit",
        }}
      />
      <Skeleton
        sx={{
          top: -image.height,
          left: 0,
          display: loading ? "absolute" : "none",
          transition: "inherit",
        }}
        animation="wave"
        variant="rectangular"
        width="100%"
        height={image.height || image.loadingHeight}
      />
    </Box>
  );
};

export default Image;
