import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import useResponsive from "../hooks/useResponsive";

const ScrollToTopOrBottom = ({ children }) => {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "xl");

  const [visible, setVisible] = useState(false);
  const [scrollTop, setScrollTop] = useState({
    current: 0,
    highest: 0,
    height: 0,
  });

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }

    setScrollTop({ current: 0, highest: 0, height: 0 });
  }, [pathname]);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const [
        {
          target: { scrollHeight, clientHeight },
        },
      ] = entries;

      setVisible(scrollHeight > clientHeight);
    });

    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, []);

  const handleOnClick = () => {
    containerRef.current.scrollTo({
      top: scrollTop.current > 0 ? 0 : scrollTop.highest,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleOnScroll = (e) => {
    setScrollTop((prev) => ({
      current: e.target.scrollTop,
      highest: Math.max(prev.highest, e.target.scrollTop),
      height: e.target.clientHeight,
    }));
  };

  return (
    <Stack
      boxSizing="border-box"
      padding={2}
      ref={containerRef}
      height="100%"
      sx={{ overflowY: "auto" }}
      position="relative"
      flex={1}
      onScroll={handleOnScroll}
    >
      <Stack
        onClick={handleOnClick}
        position="sticky"
        zIndex={2}
        top={0}
        display={
          visible && scrollTop.highest > 10 && isDesktop ? "flex" : "none"
        }
        sx={{
          maxWidth: "500px",
          width: "calc((100% - 1000px) / 2)",
          height: "100%",
          cursor: "pointer",
          "&:hover > div": {
            backgroundColor: "rgba(240,240,240,0.15)",
            color: "white",
          },
        }}
      >
        <Stack
          sx={{
            transition: "all 0.2s ease-out",
          }}
          height={scrollTop.height - 32}
          width="40%"
          paddingX={2}
          paddingY={1}
          flexDirection="row"
          gap={1}
          color="rgba(255,255,255,0.5)"
          position="absolute"
        >
          {scrollTop.current > 0 && <ArrowUpwardOutlinedIcon />}

          {scrollTop.current === 0 && scrollTop.highest > 0 && (
            <ArrowDownwardOutlinedIcon />
          )}
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};

export default ScrollToTopOrBottom;
