import { TreeItem } from "@mui/lab";
import { Typography } from "@mui/material";

const renderTree = (nodes) =>
  nodes.map((node) => (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      label={<Typography variant="h6">{node.name}</Typography>}
    >
      {Array.isArray(node.children) ? renderTree(node.children) : null}
    </TreeItem>
  ));

export default renderTree;
