import PageLayout from "../layouts/page";
import { Grid, Stack, Typography } from "@mui/material";
import ProjectCard from "../components/ProjectCard";
import projects from "../mocks/projects";

const ProjectsPage = () => {
  return (
    <PageLayout title="Work Showcase">
      <Stack gap={2}>
        <Stack gap={1}>
          <Typography width="fit-content" variant="body1">
            Greetings and welcome to my Projects page! Here, you'll discover a
            some of my work. I take immense pride in curating clean, functional
            code that ultimately fosters delightful user experiences.
          </Typography>
          <Typography width="fit-content" variant="body1">
            My portfolio spans a spectrum of projects, encompassing everything
            from small-scale websites to intricate web applications. Each
            endeavor has brought forth distinct challenges, and I've reveled in
            the process of crafting creative solutions tailored to meet my
            clients' unique requirements.
          </Typography>
          <Typography width="fit-content" variant="body1">
            It's worth noting that while some projects were collaborative
            efforts, others stand as sole creations, a testament to my ability
            to both thrive within a team and independently bring visions to
            life.
          </Typography>
        </Stack>
        <Typography variant="h6">Projects</Typography>
        <Grid container spacing={2}>
          {Object.values(projects).map((project) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={project.title}>
              <ProjectCard {...project} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </PageLayout>
  );
};

export default ProjectsPage;
