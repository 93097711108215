import TagCloud from "@frank-mayer/react-tag-cloud";
import { Stack, Typography } from "@mui/material";
import useResponsive from "../hooks/useResponsive";

const cloudOptions = [
  "VSCode",
  "TypeScript",
  "React",
  "Jest",
  "Webstorm",
  "E2E",
  "Webstorm",
  "NextJS",
  "SCSS",
  "ESLint",
  "JSON",
  "Git",
  "SASS",
  "NodeJS",
  "HTML",
  "CSS",
];

const DefaultPage = () => {
  const isDesktop = useResponsive("up", "md");

  return (
    <Stack
      textAlign="left"
      height="100%"
      flex={1}
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        overflow="hidden"
      >
        <TagCloud
          options={() => ({
            radius: isDesktop ? 350 : 220,
            maxSpeed: "fast",
          })}
          onClickOptions={{ passive: true }}
        >
          {cloudOptions}
        </TagCloud>
      </Stack>
      <Typography textAlign="center" color="primary" variant="h5">
        Search Files - Double Shift
      </Typography>
      <Typography textAlign="center" color="primary" variant="h5">
        Navigate Files From Navbar or Terminal
      </Typography>
    </Stack>
  );
};

export default DefaultPage;
