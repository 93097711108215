import { Divider, InputBase, Stack, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { blueGrey } from "@mui/material/colors";
import React, { findRoute, useTerminal } from "./index";
import { useLocation } from "react-router-dom";

const BORDER_SIZE = 25;

const TerminalInput = styled(InputBase)`
  color: white;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;
`;

const Terminal = () => {
  const { pathname } = useLocation();

  const terminalBaseValue = `user ${pathname} % `;

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { terminalCommands, terminalHistory, updateTerminalHistory } =
    useTerminal();

  const onInputChange = (e) => {
    const value = e.target.value.slice(terminalBaseValue.length);
    setInputValue(value);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const [command, ..._arguments] = inputValue.split(" ");

      updateTerminalHistory((prev) => [
        ...prev,
        <Stack key={prev.length}>
          <Typography>{terminalBaseValue + inputValue}</Typography>
          {!terminalCommands[command] && (
            <Typography>command not found : {command}</Typography>
          )}
        </Stack>,
      ]);

      if (terminalCommands[command]) {
        terminalCommands[command](_arguments.join(" "));
      }

      setInputValue("");
    }

    if (e.key === "Tab") {
      e.preventDefault();
      const { route } = findRoute(pathname);

      const lastArgument = inputValue.split(" ").pop();

      const match =
        !!lastArgument &&
        [
          ...Object.keys({
            ...terminalCommands,
            ...(typeof route === "object" ? route : {}),
          }),
        ].find(
          (v) =>
            v.toLowerCase().startsWith(lastArgument.toLowerCase()) &&
            v.toLowerCase() !== lastArgument.toLowerCase(),
        );

      if (match) {
        setInputValue((prev) => {
          const args = [...prev.split(" ")];
          args.pop();
          return [...args, match.toLowerCase()].join(" ");
        });
      }
    }
  };

  useEffect(() => {
    const terminalContent = document.getElementById("terminal-content");
    setTimeout(() => {
      terminalContent.scrollTop = terminalContent.scrollHeight;
    }, 0);
  }, [terminalHistory, open]);

  useEffect(() => {
    const terminal = document.getElementById("terminal");

    let m_pos;
    function resize(e) {
      const dy = m_pos - e.y;
      m_pos = e.y;
      terminal.style.height =
        parseInt(getComputedStyle(terminal, "").height) + dy + "px";
    }

    terminal.addEventListener("mousedown", function (e) {
      e.stopPropagation();
      if (e.target.closest("div").id && e.offsetY < BORDER_SIZE) {
        m_pos = e.y;
        document.addEventListener("mousemove", resize, false);
      }
    });

    document.addEventListener("mouseup", function () {
      document.removeEventListener("mousemove", resize, false);
    });

    return () => {
      document.removeEventListener("mousemove", resize);
      document.removeEventListener("mouseup", resize);
      terminal.removeEventListener("mousedown", resize);
    };
  }, []);

  return (
    <Stack>
      <Stack
        display={open ? "flex" : "none"}
        position="relative"
        maxHeight="75vh"
        height="200px"
        minHeight="25px"
        id="terminal"
        bgcolor={blueGrey[900]}
      >
        <Stack
          id="terminal-border"
          justifyContent="space-between"
          sx={{ cursor: "ns-resize" }}
          width="100%"
          minHeight={`${BORDER_SIZE}px`}
          height={`${BORDER_SIZE}px`}
          bgcolor={blueGrey[400]}
          color="white"
          flexDirection="row"
        >
          <Typography>Terminal</Typography>
          <Typography
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
            px={2}
            sx={{ cursor: "pointer" }}
          >
            —
          </Typography>
        </Stack>
        <Stack
          id="terminal-content"
          color="white"
          p="3px"
          flex={1}
          sx={{ overflowY: "auto" }}
        >
          {terminalHistory}
          {open && (
            <TerminalInput
              onKeyDown={handleOnKeyDown}
              spellCheck="false"
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length,
                )
              }
              onSelect={(e) => {
                if (e.target.selectionStart < terminalBaseValue.length) {
                  e.target.selectionStart = terminalBaseValue.length;
                  e.target.selectionEnd = terminalBaseValue.length;
                }
              }}
              multiline
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              value={terminalBaseValue + inputValue}
              onChange={onInputChange}
              autoFocus={open}
              type="text"
            />
          )}
        </Stack>
      </Stack>
      <Divider color={blueGrey[800]} />
      <Stack py="2px" px={2} bgcolor={blueGrey[700]}>
        <Typography
          color="white"
          sx={{ width: "max-content", cursor: "pointer" }}
          onClick={() => setOpen((prev) => !prev)}
        >
          Terminal
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Terminal;
