import { Stack, Typography } from "@mui/material";

const PageLayout = ({ children, title = "Page Title" }) => {
  return (
    <Stack flex={1}>
      <Typography mb={3} variant="h3">
        {title}
      </Typography>
      {children}
    </Stack>
  );
};

export default PageLayout;
