import PageLayout from "../layouts/page";
import { Link, Stack, Typography } from "@mui/material";

const links = [
  { href: "https://t.me/s1tnik", text: "Messenger" },
  { href: "https://github.com/s1tnik", text: "Github" },
];

const ContactPage = () => {
  return (
    <PageLayout title="Contact Page">
      <Stack gap={3}>
        <Stack gap={1}>
          <Typography variant="h5">
            Available for select freelance opportunities
          </Typography>
          <Typography variant="body1">
            Have an exciting project you need help with? Send me an email or
            contact me via instant message!
          </Typography>
        </Stack>
        <Stack>
          {links.map(({ href, text }) => (
            <Link
              key={text}
              width="max-content"
              variant="h6"
              rel="noopener"
              target="_blank"
              href={href}
              underline="hover"
            >
              {text}
            </Link>
          ))}
        </Stack>
      </Stack>
    </PageLayout>
  );
};

export default ContactPage;
