import routes, { websiteRoutes } from "../../../router/routes";
import getHtmlNameFromPath from "../../../utils/getHtmlNameFromPath";
import projects from "../../../mocks/projects";

export const nodes = [
  ...Object.keys(websiteRoutes).map((key) => ({
    id: key,
    name: getHtmlNameFromPath(key).toLowerCase(),
  })),
  {
    id: "root",
    name: "projects",
    children: Object.keys(projects).map((key) => ({
      id: `${routes.PROJECTS}/${key}`,
      name: getHtmlNameFromPath(key).toLowerCase(),
    })),
  },
];
