import projects from "../mocks/projects";
import { Navigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Chip, Container, Stack, Typography } from "@mui/material";
import PageLayout from "../layouts/page";
import Image from "../components/Image";
import routes from "../router/routes";

const ProjectPage = () => {
  const { project_name } = useParams();

  const [project, setProject] = useState(projects[project_name]);

  useEffect(() => {
    setProject(projects[project_name]);
  }, [project_name]);

  if (!project) {
    return <Navigate to={routes.NOT_FOUND} replace={true} />;
  }

  return (
    <Box position="relative">
      <Container
        maxWidth={false}
        sx={{ maxWidth: "1000px", paddingX: "0 !important" }}
      >
        <PageLayout title={project.title}>
          <Stack gap={2}>
            <Stack gap={2}>
              {project.images.map((image) => (
                <Image loadingHeight="350px" key={image} src={image} />
              ))}
            </Stack>
            <Stack gap={1}>
              <Typography variant="h5">Skills and deliverables</Typography>
              <Stack
                gap={2}
                flexWrap="wrap"
                flexDirection="row"
                alignItems="center"
              >
                {project.technologies.map((tag) => (
                  <Chip key={tag} color="primary" label={tag} />
                ))}
              </Stack>
            </Stack>
            <Stack gap={1}>
              <Typography variant="h5">Project description</Typography>
              <Typography whiteSpace="pre-line" variant="body2">
                {project.description}
              </Typography>
            </Stack>
          </Stack>
        </PageLayout>
      </Container>
    </Box>
  );
};

export default ProjectPage;
