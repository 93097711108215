import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Divider, Stack } from "@mui/material";
import Header from "./header";
import Nav from "./nav";
import { blueGrey } from "@mui/material/colors";
import NavigationHistory from "../../components/NavigationHistory";
import BottomNavigation from "../../components/BottomNavigation";
import Terminal from "../../components/Terminal";
import ScrollToTopOrBottom from "../../components/ScrollToTopOrBottom";

export default function DefaultLayout() {
  const [open, setOpen] = useState(false);

  return (
    <Stack maxHeight="100vh" height="100vh">
      <Header onOpenNav={() => setOpen(true)} />

      <Divider color="black" />

      <Stack flexDirection="row" flex={1} overflow="hidden">
        <Nav openNav={open} onCloseNav={() => setOpen(false)} />
        <Stack
          overflow="hidden"
          flex={1}
          color="primary.contrastText"
          bgcolor={blueGrey[900]}
        >
          <NavigationHistory />
          <Divider color="black" />
          <ScrollToTopOrBottom>
            <Outlet />
          </ScrollToTopOrBottom>
          <BottomNavigation />
        </Stack>
      </Stack>
      <Terminal />
    </Stack>
  );
}
