import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Modal, TextField, Autocomplete } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { grey } from "@mui/material/colors";
import routes from "../router/routes";
import getHtmlNameFromPath from "../utils/getHtmlNameFromPath";
import projects from "../mocks/projects";

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: grey[50],
  boxShadow: 24,
  borderRadius: 1,
  outline: "none",
};

const options = [
  ...Object.values(routes)
    .filter((route) => route !== "/project")
    .map((route) => ({
      path: route,
      label: getHtmlNameFromPath(route),
    })),
  ...Object.keys(projects).map((project) => ({
    path: `${routes.PROJECTS}/${project}`,
    label: getHtmlNameFromPath(`${routes.PROJECTS}/${project}`),
  })),
];

const DoubleShiftSearch = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setValue(null);
    setInputValue("");
  };

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();

  const onValueChange = (event, newValue) => {
    setValue(newValue);
    if (newValue) {
      navigate(newValue.path);
      handleClose();
    }
  };

  useEffect(() => {
    let timeOutId;
    let timesClicked = 0;

    function doubleShiftEvent(event) {
      if (event.key === "Shift") {
        timesClicked++;
        if (timesClicked >= 2) {
          setOpen((prev) => !prev);
        }
        timeOutId = setTimeout(() => (timesClicked = 0), 350);
      }
    }

    document.addEventListener("keyup", doubleShiftEvent);

    return () => {
      clearTimeout(timeOutId);
      document.removeEventListener("keyup", doubleShiftEvent);
    };
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Autocomplete
          fullWidth
          value={value}
          onChange={onValueChange}
          getOptionLabel={(option) => option.label}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchOutlinedIcon />,
              }}
              autoFocus
              placeholder="Search"
            />
          )}
        />
      </Box>
    </Modal>
  );
};

export default DoubleShiftSearch;
