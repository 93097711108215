import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import PageLayout from "../layouts/page";
import upworkLogo from "../static/images/upwork-logo.png";
import akvelonLogo from "../static/images/akvelon-logo.jpeg";
import gazpromLogo from "../static/images/gazprom-logo.png";

const experienceItems = [
  {
    title: "Top Rated Web Developer @ Upwork Inc.",
    duration: "2022 - Present",
    description:
      "Top-Rated developer on Upwork specializing in Front-end (React, NextJS) technologies with nearly 100% job success rate and client satisfaction rating.",
    website: {
      url: "https://www.upwork.com/freelancers/~011a0d306803b35852",
      text: "upwork.com",
    },
    location: "Remote",
    technologies: [
      "Javascript",
      "React",
      "NextJS",
      "Redux",
      "Typescript",
      "HTML",
      "CSS",
    ],
    logo: upworkLogo,
  },
  {
    title: "Software Development Engineer @ Gazprom.",
    duration: "2023 - Present",
    description:
      "Developing front-end solutions (B2C, B2B) with React/Next.js framework",
    website: { url: "https://www.gazprom.com/", text: "gazprom.com" },
    location: "Russia, Moscow",
    technologies: [
      "Javascript",
      "React",
      "NextJS",
      "Redux",
      "Typescript",
      "SCSS",
    ],
    logo: gazpromLogo,
  },
  {
    title: "Software Development Engineer @ Akvelon inc.",
    duration: "2021 - 2023",
    description:
      "Developing front-end solutions (B2C, B2B) with React/Next.js framework",
    website: { url: "https://akvelon.com/", text: "akvelon.com" },
    location: "Georgia, Tbilisi",
    technologies: [
      "Javascript",
      "React",
      "NextJS",
      "Redux",
      "Typescript",
      "SCSS",
    ],
    logo: akvelonLogo,
  },
  {
    title: "Web developer @ Scaletta.",
    duration: "2020 - 2021",
    description: "Onsite web developer. Building layouts from Figma.",
    location: "Russia, Kazan",
    website: {
      url: "https://www.facebook.com/groups/115386019000870/",
      text: "scaletta",
    },
    technologies: ["Javascript", "HTML", "React", "SCSS", "CMS"],
  },
];

const ExperiencePage = () => {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <PageLayout title="Professional Experience">
      <Stack mb={3} gap={1}>
        <Typography variant="body1">
          Seasoned software development engineer with over 4 years' experience.
          Proficient in understanding and translating client needs,
          troubleshooting, and developing adaptable, complex-level code
          structures. Skilled in solving diverse, non-tutorial problems with an
          emphasis on writing extendable code.
        </Typography>
      </Stack>
      <Stack maxWidth="1000px">
        {experienceItems.map(
          ({
            title,
            duration,
            description,
            website,
            location,
            technologies,
            logo,
          }) => (
            <Accordion
              onChange={handleChange(title)}
              expanded={expanded === title}
              key={title}
            >
              <AccordionSummary
                sx={{ backgroundColor: "rgba(0, 0, 0, .1)" }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Stack
                  width="100%"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>{title}</Typography>
                  <Typography mr={4}>{duration}</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  justifyContent="space-between"
                  gap={4}
                >
                  <Stack>
                    <Stack
                      mb={1}
                      flexDirection="row"
                      alignItems="center"
                      gap={2}
                    >
                      <Stack flexDirection="row" alignItems="center" gap={1}>
                        <LocationOnOutlinedIcon fontSize="small" />
                        <Typography>{location}</Typography>
                      </Stack>
                      <Stack flexDirection="row" alignItems="center" gap={1}>
                        <OpenInNewOutlinedIcon fontSize="small" />
                        <Link
                          rel="noopener"
                          target="_blank"
                          href={website.url}
                          variant="body1"
                        >
                          {website.text}
                        </Link>
                      </Stack>
                    </Stack>

                    <Typography mb={2}>{description}</Typography>

                    <Stack
                      flexWrap="wrap"
                      flexDirection="row"
                      alignItems="center"
                      gap={1}
                    >
                      {technologies.map((tech) => (
                        <Chip label={tech} key={tech} />
                      ))}
                    </Stack>
                  </Stack>
                  <Stack maxWidth="200px">
                    {logo ? (
                      <img src={logo} alt={website.text} />
                    ) : (
                      <Typography textTransform="capitalize" variant="h2">
                        {website.text}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ),
        )}
      </Stack>
    </PageLayout>
  );
};

export default ExperiencePage;
