import { useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { appRoutes } from "../../router/routes";
import { Stack, Typography } from "@mui/material";

export { default } from "./Terminal";

export const terminalCommands = {
  ls: {
    name: "ls",
    description: "list directory contents",
  },
  cd: {
    name: "cd",
    description: "change directory",
  },
  clear: {
    name: "clear",
    description: "clear terminal",
  },
  pwd: {
    name: "pwd",
    description: "print name of current directory",
  },
  echo: {
    name: "echo",
    description: "display a line of text",
  },
  help: {
    name: "help",
    description: "get information regarding a built-in shell commands",
  },
};

export const findRoute = (pathname, to) => {
  let routeStructure = pathname.slice(1).split("/");

  if (to) {
    to.split("/").forEach((folder) => {
      if (folder === "..") {
        return (routeStructure = [...routeStructure].slice(0, -1));
      }

      routeStructure = [...routeStructure, folder];
    });
  }

  let currentRoute = { ...appRoutes };

  routeStructure.forEach((folder, index) => {
    if (index === 0 && folder === "") {
      return;
    }

    if (currentRoute) {
      currentRoute = currentRoute[folder] || currentRoute[folder.toUpperCase()];
    }
  });

  return { route: currentRoute, path: routeStructure.join("/") };
};

export const useTerminal = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [terminalHistory, setTerminalHistory] = useState([
    <Stack color="white" key={0}>
      <Typography variant="body1">welcome to s1tnik shell 1.0.0</Typography>
      <Typography>type "help" to get started</Typography>
    </Stack>,
  ]);

  const handlers = useMemo(() => {
    const handlersObject = {};

    handlersObject[terminalCommands.cd.name] = (argument) => {
      const to = argument.trim();
      const { route, path } = findRoute(pathname, to);

      if (!route) {
        return setTerminalHistory((prev) => [
          ...prev,
          <Typography key={prev.length} variant="body1">
            cd: no such file or directory: {to}
          </Typography>,
        ]);
      }

      navigate(path);
    };

    handlersObject[terminalCommands.echo.name] = (argument) => {
      return setTerminalHistory((prev) => [
        ...prev,
        <Typography key={prev.length} variant="body1">
          {argument}
        </Typography>,
      ]);
    };

    handlersObject[terminalCommands.ls.name] = () => {
      const { route } = findRoute(pathname);

      if (typeof route === "object") {
        return setTerminalHistory((prev) => [
          ...prev,
          <Stack gap={3} flexDirection="row" flexWrap="wrap" key={prev.length}>
            {Object.keys(route).map((key) => {
              if (key === "/") {
                return null;
              }

              return (
                <Typography key={key} variant="body1">
                  {key.toLowerCase()}
                </Typography>
              );
            })}
          </Stack>,
        ]);
      }

      return setTerminalHistory((prev) => [...prev, <br key={prev.length} />]);
    };

    handlersObject[terminalCommands.pwd.name] = () => {
      setTerminalHistory((prev) => [
        ...prev,
        <Typography key={prev.length} variant="body1">
          {pathname}
        </Typography>,
      ]);
    };

    handlersObject[terminalCommands.clear.name] = () => {
      setTerminalHistory([]);
    };

    handlersObject[terminalCommands.help.name] = () => {
      setTerminalHistory((prev) => [
        ...prev,
        <Stack key={prev.length}>
          <Typography variant="body1">
            SH is just like bash except much more limited.
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                type ls to list directory contents
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                type cd to change directory
              </Typography>
            </li>
          </ul>
          <Stack>
            <Typography variant="body1">all commands:</Typography>
            <Stack>
              {Object.keys(terminalCommands).map((command) => (
                <Typography key={command}>
                  {command} - {terminalCommands[command].description}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Stack>,
      ]);
    };

    return handlersObject;
  }, [pathname]);
  return {
    terminalCommands: handlers,
    terminalHistory,
    updateTerminalHistory: setTerminalHistory,
  };
};
