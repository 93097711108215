const getHtmlNameFromPath = (path) => {
  if (!path) {
    return path;
  }

  if (path === "/") {
    return "index.html";
  }

  return path.startsWith("/") ? `${path.slice(1)}.html` : `${path}.html`;
};

export default getHtmlNameFromPath;
