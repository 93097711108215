import { createContext, useContext } from "react";
import useHistory from "../hooks/useHistory";

export const HistoryContext = createContext({});

export const HistoryContextProvider = ({ children }) => {
  const { history, handleRemoveHistory } = useHistory();

  return (
    <HistoryContext.Provider value={{ history, handleRemoveHistory }}>
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistoryContext = () => useContext(HistoryContext);
