const getProjectNames = (projects) => {
  const names = {};

  for (const project in projects) {
    names[project] = project;
  }

  return names;
};

export default getProjectNames;
