import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useResponsive from "../../../hooks/useResponsive";
import { blueGrey } from "@mui/material/colors";
import { TreeView } from "@mui/lab";
import renderTree from "../../../utils/renderTree";
import { nodes } from "./config";
import routes from "../../../router/routes";

const NAV_WIDTH = 300;

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isDesktop = useResponsive("up", "md");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleNodeSelect = (event, nodeId) => {
    if (nodeId === "root") {
      return;
    }

    if (nodeId.startsWith(routes.PROJECTS)) {
      return navigate(nodeId);
    }

    navigate(routes[nodeId]);
  };

  const content = useMemo(() => {
    return (
      <Box>
        <TreeView
          sx={{ fontSize: "30px" }}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={handleNodeSelect}
        >
          {renderTree(nodes)}
        </TreeView>
      </Box>
    );
  }, []);

  return (
    <Box
      bgcolor={blueGrey[700]}
      color="primary.contrastText"
      component="nav"
      display="flex"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Box width={NAV_WIDTH}>{content}</Box>
      ) : (
        <Drawer
          anchor="right"
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              paddingY: "23px",
              width: NAV_WIDTH,
              backgroundColor: blueGrey[700],
              color: "primary.contrastText",
            },
          }}
        >
          {content}
        </Drawer>
      )}
    </Box>
  );
}
