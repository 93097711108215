import ThemeProvider from "./theme";
import Router from "./router";
import DoubleShiftSearch from "./components/DoubleShiftSearch";
import { HistoryContextProvider } from "./context/historyContext";

function App() {
  return (
    <ThemeProvider>
      <HistoryContextProvider>
        <DoubleShiftSearch />
        <Router />
      </HistoryContextProvider>
    </ThemeProvider>
  );
}

export default App;
