import { useLocation, useNavigate } from "react-router-dom";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { blue, blueGrey } from "@mui/material/colors";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useHistoryContext } from "../context/historyContext";

const NavigationHistory = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { history, handleRemoveHistory } = useHistoryContext();

  const handleNavigate = (path) => (e) => {
    e.stopPropagation();
    navigate(path);
  };

  return (
    <Stack
      boxSizing="border-box"
      sx={{
        overflowX: "auto",
        scrollBehavior: "smooth",
        "&::-webkit-scrollbar": {
          height: "8px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#263238",
        },
      }}
      bgcolor={blueGrey[700]}
      flexDirection="row"
      divider={<Divider orientation="vertical" color="black" />}
      onWheel={(e) => {
        if (e.deltaY > 0) {
          e.currentTarget.scrollLeft += 100;
        } else {
          e.currentTarget.scrollLeft -= 100;
        }
      }}
    >
      {history.map(({ path, name }, index) => (
        <Stack
          key={path}
          onClick={handleNavigate(path)}
          alignItems="center"
          flexDirection="row"
          paddingY="3px"
          paddingX="6px"
          gap={2}
          bgcolor={blueGrey[800]}
          sx={{ cursor: "pointer" }}
        >
          <Typography
            variant="body2"
            color={pathname === path ? blue[400] : "inherit"}
          >
            {name}
          </Typography>
          <IconButton onClick={handleRemoveHistory(index)} size="small">
            <CloseOutlinedIcon sx={{ color: "white" }} fontSize="7px" />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  );
};

export default NavigationHistory;
