import projects from "../mocks/projects";
import getProjectNames from "../utils/getProjectNames";

export const websiteRoutes = {
  EXPERTISE: "/expertise",
  PROJECTS: "/projects",
  EXPERIENCE: "/experience",
  CONTACT: "/contact",
};

const routes = {
  NOT_FOUND: "/not_found",
  ...websiteRoutes,
};

export const appRoutes = {
  ...routes,
  PROJECTS: getProjectNames(projects),
};

export default routes;
