import { useEffect, useState } from "react";
import { Button, Divider, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistoryContext } from "../context/historyContext";
import useResponsive from "../hooks/useResponsive";

const BottomNavigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isDesktop = useResponsive("up", "md");

  const { history } = useHistoryContext();

  const [navigation, setNavigation] = useState([null, null]);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  useEffect(() => {
    const index = history.findIndex((item) => item.path === pathname);
    setNavigation([history[index - 1], history[index + 1]]);
  }, [pathname, history]);

  if (pathname === "/" || navigation.every((v) => !v)) {
    return null;
  }

  return (
    <Stack paddingX={2} paddingBottom={2} gap={2} justifyContent="flex-end">
      <Stack sx={{ opacity: 0.6 }}>
        <Divider light color="white" />
      </Stack>
      <Stack
        width="100%"
        flexDirection="row"
        justifyContent={
          navigation.every((v) => v)
            ? "space-between"
            : navigation[0]
            ? "flex-start"
            : "flex-end"
        }
      >
        {navigation.map((item, index) =>
          item ? (
            <Button
              size={isDesktop ? "medium" : "small"}
              key={item.path}
              onClick={handleNavigation(item.path)}
              variant="contained"
              endIcon={index === 1 ? <ArrowForwardIcon /> : null}
              startIcon={index === 0 ? <ArrowBackIcon /> : null}
            >
              {item.name}
            </Button>
          ) : null,
        )}
      </Stack>
    </Stack>
  );
};

export default BottomNavigation;
