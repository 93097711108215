import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../router/routes";
import getHtmlNameFromPath from "../utils/getHtmlNameFromPath";
import projects from "../mocks/projects";
import getProjectNames from "../utils/getProjectNames";

const useHistory = () => {
  const [history, setHistory] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleRemoveHistory = (index) => (e) => {
    e.stopPropagation();

    if (e.altKey) {
      const newHistory = [history[index]];
      setHistory(newHistory);
      return navigate(newHistory[0].path);
    }

    const newHistory = [...history];
    const deletedItem = newHistory[index];
    newHistory.splice(index, 1);
    setHistory(newHistory);

    if (newHistory.length === 0) {
      return navigate("/");
    }

    if (index === 0) {
      return navigate(newHistory[0].path);
    }

    if (deletedItem.path === pathname) {
      navigate(newHistory[index - 1].path);
    }
  };

  useEffect(() => {
    if (
      history.some(({ path }) => pathname === path) ||
      pathname === "/" ||
      ![
        ...Object.values(routes),
        ...Object.values(getProjectNames(projects)).map(
          (v) => "/projects/" + v
        ),
      ].includes(pathname)
    ) {
      return;
    }

    setHistory((prev) => [
      ...prev,
      { path: pathname, name: getHtmlNameFromPath(pathname.split("/").pop()) },
    ]);
  }, [pathname, history]);

  return { history, handleRemoveHistory };
};

export default useHistory;
