import { Stack, Typography } from "@mui/material";

const ExpertiseCard = ({ title, description, ...props }) => {
  return (
    <Stack p={3} bgcolor="background.neutral" gap={1} {...props}>
      <Typography color="text.primary" variant="h5">
        {title}
      </Typography>
      <Typography color="text.secondary" variant="body1">
        {description}
      </Typography>
    </Stack>
  );
};

export default ExpertiseCard;
