import coaching_platform_images from "../static/images/projects/coaching-platform";
import admin_panel_images from "../static/images/projects/admin-panel";
import notes_manager_images from "../static/images/projects/notes-manager";
import scaletta_images from "../static/images/projects/scalleta";
import akvelon_website_images from "../static/images/projects/akvelon-website";
import logotype_images from "../static/images/projects/logotype";
import rebel_nation_images from "../static/images/projects/rebel-nation";
import pixel_patties_images from "../static/images/projects/pixel_patties";
import spotnana_images from "../static/images/projects/spotnana";
import tasma_images from "../static/images/projects/tasma";

const projects = {
  akvelon_website: {
    title: "Akvelon personal website",
    project_name: "akvelon_website",
    images: akvelon_website_images,
    technologies: [
      "Next.js",
      "React",
      "Redux",
      "Material-UI",
      "Figma",
      "CSS",
      "Git",
      "HTML",
      "JavaScript",
      "TypeScript",
    ],
    description:
      "Akvelon's Personal Website was developed using modern web technologies, including HTML, CSS, and JavaScript. The website serves as a showcase for Akvelon, an IT company specializing in software development, cloud computing, AI, and data analytics. It features a sleek and modern design, providing information about Akvelon's expertise, services, and portfolio of innovative projects.",
  },
  coaching_platform: {
    title: "Coaching platform",
    project_name: "coaching_platform",
    images: coaching_platform_images,
    technologies: [
      "Next.js",
      "Redux",
      "Material-UI",
      "Figma",
      "CSS",
      "Git",
      "HTML",
      "JavaScript",
      "TypeScript",
    ],
    description:
      "Web portal was created using Nextjs, Material Ui and Firebase. The portal is used by coaches to manage their clients and their progress.",
  },
  scaletta: {
    title: "Scaletta",
    project_name: "scaletta",
    images: scaletta_images,
    technologies: ["JavaScript", "React", "Redux", "CSS", "HTML", "Git"],
    description:
      "Website offers realistic and high-quality 1/6 scale figurines of real characters and people. Developed using modern web technologies such as HTML, CSS, and JavaScript, platform provides a user-friendly experience for collectors, fans, and gift seekers.",
  },
  admin_panel: {
    title: "Minimalistic admin panel",
    project_name: "admin_panel",
    images: admin_panel_images,
    technologies: [
      "JavaScript",
      "React",
      "Redux",
      "Material-UI",
      "CSS",
      "HTML",
      "Git",
    ],
    description:
      "Admin panel is a standalone web application that provides powerful and efficient management capabilities for various aspects of business operations. Developed using modern web technologies, admin panel offers a secure and user-friendly interface for managing backend operations.",
  },
  notes_manager: {
    title: "Notes manager",
    project_name: "notes_manager",
    images: notes_manager_images,
    technologies: ["JavaScript", "React", "Redux", "CSS", "HTML", "Git"],
    description:
      "Notes manager enables users to take down notes or thoughts in a text format. This is fully functional drag and drop board.\n" +
      "\n" +
      "A list of technologies used within the project:\n" +
      "\n" +
      "redux-persist - persisting the state of the app\n" +
      "redux-thunk - async mutations inside redux\n" +
      "react-dnd - drag and drop logic",
  },
  logotype: {
    title: "Logotype landing",
    project_name: "logotype",
    images: logotype_images,
    technologies: ["JavaScript", "React", "CSS", "HTML"],
    description:
      "Sleek, modern, and user-friendly. The landing page combines captivating visuals with intuitive navigation, ensuring an engaging and informative experience for every visitor.",
  },
  rebel_nation: {
    title: "Rebel nation",
    project_name: "rebel_nation",
    images: rebel_nation_images,
    technologies: ["JavaScript", "CSS", "HTML"],
    description:
      "Dynamic, visually captivating, and seamlessly navigable. The landing page merges enticing visuals with intuitive navigation, ensuring a welcoming and immersive experience for all visitors.",
  },
  pixel_patties: {
    title: "Pixel patties",
    project_name: "pixel_patties",
    images: pixel_patties_images,
    technologies: ["JavaScript", "CSS", "HTML", "NextJS"],
    description:
      "The website optimizes a panoramic layout to offer an engaging, immersive, and artistically enriched browsing experience.",
  },
  spotnana: {
    title: "Spotnana",
    project_name: "spotnana",
    images: spotnana_images,
    technologies: ["JavaScript", "CSS", "HTML", "React", "Material UI"],
    description:
      "Travel-as-a-Service Platform powers a new generation of global travel experiences through a modern architecture that is built on microservices and runs entirely in the cloud.",
  },
  tasma: {
    title: "Tasma",
    project_name: "tasma",
    images: tasma_images,
    technologies: ["JavaScript", "CSS", "HTML", "NextJS", "Material UI"],
    description:
      "The innovative web application that simplifies the process of discovering movies by simply pasting a video URL.",
  },
};

export default projects;
