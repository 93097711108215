import { useEffect, useState } from "react";
import { LinearProgress, Stack, Typography } from "@mui/material";
import PageLayout from "../layouts/page";
import ExpertiseCard from "../components/ExpertiseCard";

const expertiseCards = [
  {
    title: "Software Development",
    description:
      "Experienced in both functional and OOP: Python, JavaScript, TypeScript, NodeJS",
  },
  {
    title: "Frontend Dev React, NextJS ",
    description:
      "Passionate about UI/UX. Over 3 years of development experience in HTML, CSS, JS, React and NextJS frameworks.",
  },
];

const technologies = [
  { title: "Front-end", value: 95, color: "primary" },
  { title: "Back-end", value: 80, color: "error" },
  { title: "ReactJS", value: 90, color: "primary" },
  { title: "NodeJS", value: 70, color: "error" },
];

const ExpertisePage = () => {
  const [initRender, setInitRender] = useState(true);

  useEffect(() => {
    setInitRender(false);
  }, []);

  return (
    <PageLayout title="My Expertise">
      <Stack
        gap={6}
        flexDirection={{ xs: "column", lg: "row" }}
        alignItems="flex-start"
      >
        <Stack gap={1} flex={4}>
          <Typography variant="body1">
            Across over four years, my journey as a developer has been marked by
            diverse experiences, from remote roles with agencies to consultancy
            for startups. In collaboration with skilled professionals, I've
            contributed to the creation of web products catering to both
            business and consumer needs.
          </Typography>
          <Typography variant="body1">
            My specialty lies in crafting high-performing, responsive websites
            that prioritize speed, usability, and adherence to industry best
            practices. With a primary focus on front-end development, I excel in
            HTML, CSS, and JavaScript, adept at constructing small to medium web
            applications, custom plugins, intricate features, dynamic
            animations, and interactive layouts.
          </Typography>
          <Typography variant="body1">
            I'm dedicated to delivering not just functional but exceptional
            digital experiences, ensuring that every project I undertake is
            optimized for seamless user interaction and visual excellence.
          </Typography>
        </Stack>
        <Stack flex={5} gap={8}>
          <Stack gap={2}>
            {technologies.map(({ title, value, color }, index) => (
              <Stack key={index} gap={1}>
                <Typography variant="body1" fontWeight={600}>
                  {title}
                </Typography>
                <LinearProgress
                  sx={{
                    backgroundColor: "lightgrey",
                    ".MuiLinearProgress-bar": {
                      transition: `all ${1.3 + value / 100}s ease-out`,
                    },
                  }}
                  color={color}
                  variant="determinate"
                  value={initRender ? 0 : value}
                />
              </Stack>
            ))}
          </Stack>
          <Stack flexDirection={{ xs: "column", sm: "row" }} gap={3}>
            {expertiseCards.map((card, index) => (
              <ExpertiseCard key={index} {...card} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </PageLayout>
  );
};

export default ExpertisePage;
