import { useNavigate } from "react-router-dom";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import Image from "./Image";
import routes from "../router/routes";

const ProjectCard = ({ images, title, project_name }) => {
  const navigate = useNavigate();

  const navigateToProject = () => {
    navigate(`${routes.PROJECTS}/${project_name}`);
  };

  return (
    <Card>
      <CardActionArea onClick={navigateToProject}>
        <Image height={180} src={images[0]} alt={title} />
        <CardContent>
          <Typography gutterBottom variant="body1" component="div">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProjectCard;
