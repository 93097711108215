import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  AppBar,
  Breadcrumbs,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import useResponsive from "../../../hooks/useResponsive";
import getHtmlNameFromPath from "../../../utils/getHtmlNameFromPath";
import MenuIcon from "@mui/icons-material/Menu";

export default function Header({ onOpenNav }) {
  const isDesktop = useResponsive("up", "md");
  const { pathname } = useLocation();

  const breadcrumbs = useMemo(() => {
    let paths = pathname
      .split("/")
      .slice(1)
      .map((item, index, array) =>
        (index + 1) % 2 !== 0 && index + 1 !== array.length
          ? item
          : getHtmlNameFromPath(item),
      );

    paths[0] = paths[0] || "index.html";

    return paths.map((item) => {
      return (
        <Typography color="inherit" key={item}>
          {item}
        </Typography>
      );
    });
  }, [pathname]);

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: blueGrey[700],
        paddingY: 1,
        paddingX: 2,
      }}
    >
      <Stack position="relative" justifyContent="center">
        {!isDesktop && (
          <IconButton
            sx={{ position: "absolute", right: 0 }}
            onClick={onOpenNav}
          >
            <MenuIcon sx={{ color: "white" }} />
          </IconButton>
        )}
        <Breadcrumbs color="inherit" separator="›">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
    </AppBar>
  );
}
